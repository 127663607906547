import React from 'react'
import { graphql } from 'gatsby'
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import Hero from '../../components/heroSubpage'
import CarouselQuote from '../../components/carouselQuote'

const About = ({ data }) => {
  const post = data.markdownRemark
  return (
    <Layout>
      <div id="sub-page">
        <Hero
          class="intro-65"
          image={post?.frontmatter?.image?.childImageSharp?.gatsbyImageData}
          foreimage={null}
          imageAltText={post.frontmatter.alttext}
          title={post.frontmatter.title}
          subtitle={post.frontmatter.subtitle}
          type="root"
        />
      </div>

      <main>
        <section className="bg-white">
          <MDBContainer>
            <MDBRow>
              <MDBCol>
              <div className="font-w-400 text-xs-extra-large divlink text-opening-para" dangerouslySetInnerHTML={{__html: post.frontmatter.openingpara}} />
                <div className="mt-3 richtext divlink" dangerouslySetInnerHTML={{ __html: post.html }} />
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </section>

        <section className="blockback">
          {post.frontmatter.quote.map((quotes, index) => {
            return (
              <CarouselQuote key={index} quotenumber={quotes.quotenumber} strapline={quotes.strapline} author={quotes.author} />
            )
          })}
        </section>
      </main>
    </Layout>
  )
}
export default About 

export function Head({ data, location }) {
  const post = data.markdownRemark
  return (
    <SEO
      title={post.frontmatter.meta.title}
      description={post.frontmatter.meta.description}
      url={location.pathname} 
      image={post.frontmatter.image.childImageSharp?.gatsbyImageData?.images?.fallback?.src}          
      canonical={location.pathname}                  
    />
  )
}

export const query = graphql`
  query { 
    markdownRemark(frontmatter: { name: { eq: "about" } }) {
      frontmatter {
        meta {
          title
          description 
        }
        title
        subtitle
        openingpara
        alttext
        image {
          childImageSharp {
            gatsbyImageData(quality: 90) 
          }
        }
        quote {
          quotenumber
          strapline
          author
        }
        section {
          title
          subtitle
          description
          image {
            childImageSharp {
              gatsbyImageData(quality: 90) 
            }
          }
          placement
          alttext
          btnlink
          btncolour
        }
      }
      html
    }
  }
`
